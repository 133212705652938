<template>
  <div>
    <div>
      <router-link 
        :to="currentElement.to" 
        tag="img" 
        :src="require(`@/assets/images/${currentElement.imgName}`)" 
        :alt="currentElement.imgName"
        class="card-icon"
      >
      </router-link>
      <div class="container card-content">
        <div class="row">
          <div class="col">
            <button class="btn" @click="showPrevElement" :disabled="this.reachedMaxLeft" aria-label="boton flecha de navegacion izquierda">
              <i class="fas fa-chevron-left"></i>
            </button>
          </div>
          <div class="col-6">
            <h2 class='headline'>{{currentElement.headline}}</h2>
          </div>
          <div class="col">
            <button class="btn" @click="showNextElement" :disabled="this.reachedMaxRight" aria-label="boton flecha de navegacion derecha">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Carousel",
  props: { cards: Array },
  data() {
    return {
      currentElementIndex: 0
    };
  },
  computed: {
    currentElement() {
      return this.cards[this.currentElementIndex];
    },
    reachedMaxLeft() {
      return this.currentElementIndex === 0;
    },
    reachedMaxRight() {
      return this.currentElementIndex === this.cards.length - 1;
    }
  },
  methods: {
    showNextElement() {
      this.currentElementIndex++;
    },
    showPrevElement() {
      this.currentElementIndex--;
    },
  }
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
.card-icon {
  width: 100%;
}
.card-content {
  padding: 20px;
}
.btn {
  border: none;
  background: transparent;
  cursor: pointer;
}
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (max-width: 768px) {
  .btn {
    display: none;
  }
} */
.btn:focus {
  outline: none;
}
.btn:disabled {
  opacity: 0.2;
  cursor: default;
}
</style>