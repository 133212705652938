var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.recipes.recipesColOne),function(recipe,index){return _c('div',{key:index,staticClass:"col"},[_c('router-link',{staticClass:"border-img",attrs:{"to":{
                    name: 'SingleRecipe',
                    params: {
                        slug: 'cocteles',
                        id: recipe.id
                    } 
                },"tag":"img","src":recipe.image_url,"alt":recipe.name}}),_c('p',{staticClass:"recipe-name",domProps:{"textContent":_vm._s(recipe.name)}})],1)}),0),_c('div',{staticClass:"row"},_vm._l((_vm.recipes.recipesColTwo),function(recipe,index){return _c('div',{key:index,staticClass:"col"},[_c('router-link',{staticClass:"border-img",attrs:{"to":{
                    name: 'SingleRecipe',
                    params: {
                        slug: 'cocteles',
                        id: recipe.id
                    } 
                },"tag":"img","src":recipe.image_url,"alt":recipe.name}}),_c('p',{staticClass:"recipe-name",domProps:{"textContent":_vm._s(recipe.name)}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }