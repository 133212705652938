<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel">
                <slot></slot>
                <form class="form-inline">
                    <input 
                        class="form-control mr-sm-2" 
                        type="search" 
                        placeholder="Daiquiri" 
                        aria-label="Search" 
                        v-model="search"
                    >
                    <router-link :to="to">
                        <button class="btn btn-outline-success my-2 my-sm-0" type="button" @click="buildSearchQuery">Buscar</button>
                    </router-link>
                </form>
            </div>
        </transition>
    </div>
</template>
<script>
import { store, mutations } from '@/store.js';
export default {
    data() {
        return {
            search: '',
            to: {}
        }
    },
    methods: {
        closeSidebarPanel: mutations.toggleNav,
        buildSearchQuery: function() {
            // hyphenate the search query
            var hyphenatedSearch = this.search.toLowerCase().replace(/\s/g, '+');
            this.to = {
                name: 'BuscarRecetas',
                params: {
                    query: hyphenatedSearch,
                    endpoint: `wprm_recipe/?search=${hyphenatedSearch}`,
                    header: this.search,
                    slug: 'buscar'
                }
            }
            this.search = '';
        }
    },
    computed: {
        isPanelOpen() {
            return store.isNavOpen
        }
    },
}
</script>

<style>
.sidebar .sidebar-panel #footer {
    padding: 0;
}
.sidebar .sidebar-panel form.form-inline .btn-outline-success {
  width: 100%;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-weight: bold;
  border-bottom: 2px solid #751e26;
}
.sidebar .sidebar-panel form.form-inline a {
    width: 100%;
}
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease;
}
.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
}
.sidebar-backdrop {
    background-color: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}
.sidebar-panel {
    overflow-y: auto;
    background-color: #bee4fa;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 300px;
}
ul.sidebar-panel-nav {
    padding: 0;
}
input.form-control.mr-sm-2 {
    margin-right: 0 !important;
}
</style>