<template>
    <div class="container">

        <h2 v-text="header"></h2>    

        <RecipesListVertical :recipes="recipes" />

        <router-link :to="{ path: '/recetas-populares', params: { slug: 'populares' } }">
            <button class="btn btn-danger">Ver Más Recetas Populares</button>
        </router-link>

    </div>
</template>

<script>
import RecipesListVertical from '@/components/lists/RecipesListVertical';
export default {
    data() {
        return {
            recipes: [],
            header: "Popular 📈",
        }
    },
    mounted() {
        const limit = 5;
        fetch(`https://coctelia.com/wp-json/wordpress-popular-posts/v1/popular-posts?term_id=-256,-244&limit=${limit}`)
            .then(response => response.json())
            .then(data => {
                data.map((data) => {
                    this.recipes.push(data.recipe);
                });
            });
    },
    components: {RecipesListVertical}
}
</script>