<template>
  <div>
    <transition name="modal">
      <div class="modal-mask" v-show="showModal">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div class="close-button">
                <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-header">
                <h3 v-text="equipment.name"></h3>
              </div>
            </div>
            <div class="modal-body">
              {{ equipment.description }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  props: {
    showModal: {
      type: Boolean,
      default: () => false
    },
    equipment: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: flex;
  bottom: 0;
  position: absolute;
  width: 100%;
}
.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-header h3 {
  font-size: 1rem;
  font-weight: bold;
}
.modal-header {
  display: block;
}
.modal-header,
.modal-body {
  border: none;
  padding: 0;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>