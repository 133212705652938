import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lists from '@/views/Lists.vue'
import Donate from '@/views/Donate.vue'
import SingleRecipe from '@/views/SingleRecipe.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/recetas-nuevas',
    name: 'RecetasNuevas',
    component: () => import(/* webpackChunkName: "newrecipes" */ '@/views/RecipesList.vue')
  },
  {
    path: '/recetas-populares',
    name: 'RecetasPopulares',
    component: () => import(/* webpackChunkName: "popularrecipes" */ '@/views/RecipesList.vue')
  },  
  {
    path: '/buscar/:query',
    name: 'BuscarRecetas',
    component: () => import(/* webpackChunkName: "search" */ '@/views/RecipesList.vue')
  },
  {
    path: '/ingredientes',
    name: 'Ingredientes',
    component: Lists,
  },
  {
    path: '/colecciones',
    name: 'Colecciones',
    component: Lists,
  },
  {
    path: '/cocteles',
    name: 'Cocteles',
    component: Lists,
  },
  {
    path: '/favoritos',
    name: 'Favorites',
    component: () => import(/* webpackChunkName: "recipelist" */ '@/views/Favorites.vue')
  },
  {
    path: '/cocteles/:id',
    name: 'SingleRecipe',
    component: SingleRecipe,
  },
  {
    path: '/:slug/:id',
    name: 'RecipesList',
    component: () => import(/* webpackChunkName: "recipelist" */ '@/views/RecipesList.vue')
  },
  {
    path: '/libros',
    name: 'Books',
    component: () => import(/* webpackChunkName: "books" */ '@/views/Books.vue')
  },
  {
    path: '/donacion',
    name: 'Donate',
    component: Donate,
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Lists,
  },
  {
    path: '/:slug',
    name: 'Lists',
    component: Lists,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
