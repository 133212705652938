<template>
    <div>
        <ul class="list-unstyled list-recipes-list">
            <li class="media" v-for="(recipe, index) in recipes" :key="index">
                <router-link :to="`/cocteles/${recipe.id}`">
                    <figure>
                        <img 
                            class="mr-3 border-img" 
                            :src="recipe.image_url ? recipe.image_url : recipe.fimg_url" 
                            :alt="recipe.name"
                        />
                    </figure>
                </router-link>
                <div class="media-body">
                    <h2 class="mt-0 mb-1" v-text="recipe.name"></h2>
                    <div v-if="!isMobile()">
                        <span 
                            class="badge badge-primary" 
                            v-for="(ingredients, idx) in recipe.ingredients_flat" 
                            :key="idx" 
                            v-text="ingredients.name"
                        >
                        </span>
                    </div>
                    <div v-else>
                        <div v-show="recipe.showDate">
                            <span class="badge badge-light" v-text="recipe.date"></span>
                        </div>
                        <div>
                            <span class="badge badge-primary" v-text="recipe.category"></span>
                        </div>
                    </div>
                    <div>
                        <span class="badge badge-danger" v-if="recipe.video_embed">Video</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { store } from '@/store.js';
export default {
    data() {
        return {
            faves: []
        }
    },
    props: {
        recipes: {
			type: Array,
			default: () => []
        }
    },
    mounted() {
        window.scrollTo(0,0);
        store.isNavOpen = false;
    },
    methods: {
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? true : false;
        }
    },
}
</script>

<style scoped>
.recipes-list {
    list-style: none;
    padding: 0;
}
.recipes-list .recipe {
    margin-bottom: 30px;
}
.badge-primary {
    margin: 0 5px 0 0;
}
.list-recipes-list .media {
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: left;
}
.list-recipes-list .media img {
  width: 175px;
}
.fas.fa-chevron-left {
    position: fixed;
    right: 90%;
    top: 30px;
    font-size: 30px;
    z-index: 1;
}
.form-inline {
    display: block;
    margin: 20px 0;
}
.media-body h2 {
    font-size: 22px;
}
@media screen and (min-width: 767px) {
    .list-recipes-list .media img {
        width: 350px;
    }
}
</style>