<template>
    <div class="loader"></div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped>
/* Loader */
.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #2d96ed;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>