<template>
    <div v-if="recipe">
        <h1 v-text="recipe.name"></h1>
        <figure>
            <img :src="recipe.image_url" class="card-img-top" />
        </figure>
        <youtube 
            v-show="video" 
            :video-id="recipe.video" 
            player-width="100%" 
            player-height="210"
        >
        </youtube>
        <div class="container">
            <!-- Recipe Details -->
            <div class="container recipe-reqs">

                <div class="d-flex justify-content-between">
                    <div>
                        <span><strong>Calorías</strong></span><br/>
                        <span class="badge badge-success" v-text="recipe.nutrition.calories"></span>
                    </div>
                    <div class="favorite">
                        <i class="far fa-star" @click="addFave(recipe.id)" v-show="!favorited"></i>
                        <i class="fas fa-star" @click="removeFave(recipe.id)" v-show="favorited"></i>
                    </div>
                </div>

                <div class="recipe-info">
                    <strong>Ingredientes</strong>
                    <div>
                        <span 
                            v-for="(ingredient, index) in recipe.ingredients_flat" 
                            :key="index" 
                            class="badge badge-primary"
                            v-text="ingredient.name"
                        >
                        </span>
                    </div>
                </div>

                <div class="recipe-info" v-if="recipe.equipment.length">
                    <UtensilsModal @close="showModal = false" :equipment="equipment" :showModal="showModal" />
                    <strong>Utensilios</strong>
                    <div>
                        <span 
                            v-for="(equipment, index) in recipe.equipment" 
                            :key="index" 
                            class="badge badge-light"
                            v-text="equipment.name"
                            @click="modalFunc(equipment)"
                        >
                        </span>
                    </div>
                </div>

            </div>

            <div class="container recipe-details">
                <h2>Ingredientes</h2>

                <!-- Toggle Switch -->
                <label class="switch">
                    <input type="checkbox" id="togBtn" v-on:click="changeUnit()">
                    <div class="slider round"><!--ADDED HTML -->
                        <span class="on">{{ labels.unitOz }}</span>
                        <span class="off">{{ labels.unitMl }}</span><!--END-->
                    </div>
                </label>
                <!-- End Toggle Switch -->

                <ul class="list-group list-group-flush ingredients-list">
                    <li 
                        v-for="(ingredient, index) in recipe.ingredients_flat" 
                        :key="index"  
                        class="list-group-item"
                    >
                        <router-link :to="{ 
                            name: 'RecipesList', 
                            params: { 
                                slug: 'ingredientes',
                                id: ingredient.id, 
                                name: ingredient.name 
                            } }" 
                        >
                        {{ ingredient.amount }} {{ ingredient.unit }} <span class="badge badge-pill badge-primary">{{ ingredient.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            
            <div class="container recipe-details">
                <h2>Preparación</h2>
                <ol class="list-group list-group-flush instructions-list">
                    <li 
                        v-for="(instruction, index) in recipe.instructions_flat" 
                        :key="index" 
                        v-text="index + 1 + '. ' + instruction.text.replace( /(<([^>]+)>)/ig, '')" 
                        class="list-group-item"
                    >
                    </li>
                </ol>
            </div>
            <!-- End Recipe Details -->

            <!-- Social Share Buttons -->
            <div class="recipe-share">
                <p class="font-weight-bold">Compartir:</p>
                <facebook-button
                    :shareUrl="shareLink"
                    v-bind:isBlank="false" btnText
                />
                <pinterest-button
                    :shareUrl="shareLink"
                    :picture="(recipe.pin_image_url) ? recipe.pin_image_url : recipe.image_url"
                    v-bind:isBlank="false" btnText
                />
                <twitter-button
                    :shareUrl="shareLink"
                    v-bind:isBlank="false" btnText
                />
            </div>
            <!-- End Social Share Buttons -->
        </div>
    </div>
</template>

<script>
import utensils from '@/text.js';
import UtensilsModal from '@/components/UtensilsModal';
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import PinterestButton from "vue-share-buttons/src/components/PinterestButton";
export default {
    name: 'SingleRecipe',
	data() {
		return {
            showModal: false,
			labels: { unitOz: "OZ", unitMl: "ML" },
            recipe: this.$route.params.recipe,
            shareLink: window.location.href,
            video: false,
            loading: false,
            faves: [],
            favorited: false,
            equipment: {},
            utensils: utensils
		}
    },
    async mounted() {
        window.scrollTo(0,0);

        if (!this.recipe) {
            this.loading = true;
            await fetch(`${this.$apiUrl}/wprm_recipe/${this.$route.params.id}`)
                .then(response => response.json())
                .then(data => { 
                    data.recipe.equipment.map(tool => {
                        for (let i = 0; i < this.utensils.length; ++i) {
                            if (this.utensils[i].id == tool.id) {
                                tool.description = this.utensils[i].description;
                            }
                        }
                    })
                    this.recipe = data.recipe;
                })
                .finally(() => (this.loading = false));
        }

        // check if recipe has a video
        if (this.recipe.video_embed) {
            this.recipe.video = this.getYouTubeVideo(this.recipe.video_embed);
            this.video = !this.video;
        }

        // load localStorage data
        if (localStorage.getItem('recipes')) {
            try {
                this.faves = JSON.parse(localStorage.getItem('recipes'));
            } catch(e) {
                localStorage.removeItem('recipes');
            }
        }
        // check if recipe has been saved
        this.favorited = (this.faves.includes(this.recipe.id)) ? true : false;
    },
	methods: {
        // shows the UtensilsModal
        // assigns equipment to pass to the component
        modalFunc(equipment) {
            this.showModal = true;
            this.equipment = equipment;
        },
        // fetches the YouTube video associated with recipe
        // using embed link
        getYouTubeVideo(video) {
            return this.$youtube.getIdFromURL(video);
        },
		//changes unit of measurement for recipe
		changeUnit() {
            this.recipe.showOz = !this.recipe.showOz;
            //convert that unit to another unit
            let currIngr = this.recipe.ingredients_flat;
            for (var i = 0; i < currIngr.length; i++) {
                var lowerCaseUnit = currIngr[i].unit.toLowerCase();
                //if current unit not equal to unit that was pressed then switch units
                if (lowerCaseUnit === 'ml' && this.recipe.showOz) {
                    //29.574 mL is equal to 1oz, but I rounded up to 30 mL
                    // round to 2 decimal places
                    currIngr[i].amount = Math.round((currIngr[i].amount / 30) * 100) / 100;
                    currIngr[i].unit = 'oz';
                } else if (lowerCaseUnit === 'oz' && !this.recipe.showOz) {
                    // round to nearest whole number
                    currIngr[i].amount = Math.round(currIngr[i].amount * 30);
                    currIngr[i].unit = 'mL';
                }
            }
		},
        addFave(id) {
            if (this.faves.includes(id)) { 
                return; 
            } else {
                this.faves.push(id);
                this.favorited = !this.favorited;
                this.saveFaves();
            }
        },
        saveFaves() {
            const parsed = JSON.stringify(this.faves);
            localStorage.setItem('recipes', parsed);
        },
        removeFave(id) {
            const index = this.faves.indexOf(id);
            this.faves.splice(index,1);
            this.favorited = !this.favorited;
            this.saveFaves();
        },
	},
	components: {
        FacebookButton, 
        TwitterButton, 
        PinterestButton,
        UtensilsModal
    }
}
</script>

<style scoped>
.favorite {
    margin-bottom: 10px;
    font-size: 22px;
}
.favorite .fas.fa-star {
    color: #fbc02d;
}
.recipe-reqs {
    text-align: left;
    margin: 10px 0 20px;
}
.recipe-reqs .recipe-info {
    margin-top: 10px;
}
.badge-light {
    border: 1px solid #9c9c9c;
}
.badge-light,
.badge-primary {
    margin: 0 2px 0 0;
}
.row.recipe-info {
    margin: 20px 0;
    text-align: left;
}
div.recipe-share {
	margin: 20px 0;
}
.container.recipe-details {
    padding: 0 0 20px 0;
}
ul.ingredients-list,
ol.instructions-list {
	text-align: left;
}
ul.ingredients-list .badge-pill {
    font-size: 16px;
}
.badge-light:after {
    content: "\2139";
    font-size: 14px;
    padding-left: 3px;
}
/* Toggle Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 34px;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #287bff;
	-webkit-transition: .4s;
	transition: .4s;
}
.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 30px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}
input:checked + .slider:before {
	-webkit-transform: translateX(33px);
	-ms-transform: translateX(33px);
	transform: translateX(33px);
}
.off {
    left: 28%;
}
.on {
    left: 75%;
}
.on, .off {
	color: #000;
    font-weight: bold;
	position: absolute;
	transform: translate(-50%,-50%);
	top: 50%;
	font-size: 14px;
}
.slider.round,
.slider.round:before {
	border-radius: 5px;
}
/* Toggle Switch */
</style>