<template>
    <div class="container">
        <h1 v-text="header"></h1>

        <div class="text-container">
            <p>
                Hola amigxs! 👋🏼<br/>
                <br/>
                Con tu apoyo puedo seguir trayendoles<br/>
                las mejores recetas de cócteles de todo tipo.<br/>
                <br/>
                Puedes usar el botón abajo para hacer<br/>
                una pequeña contribución para poder hacer<br/>
                este app aún mejor para Uds.<br/>
                <br/>
                Gracias por su ayuda!
            </p>
        </div>

        <div class="form-container">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="business" value="BMGHZH8QSP3HS" />
            <input type="hidden" name="item_name" value="Ayúdame a poder seguir publicando las mejores recetas de cócteles." />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/es_XC/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donar con el botón PayPal" />
            <img alt="" border="0" src="https://www.paypal.com/es_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </div>

    </div>
</template>

<script>
import { store } from '@/store.js';
export default {
    name: 'Donate',
    data() {
        return {
            header: 'Donación'
        }
    },
    mounted() {
        store.isNavOpen = false;
    },
}
</script>