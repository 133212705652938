<template>
    <div>
      <h2 v-text="header"></h2>

      <div class="row">

        <div class="col">
          <router-link 
            :to="cards[0].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[0].card.image}`)" 
            :alt="`${cards[0].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[1].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[1].card.image}`)" 
            :alt="`${cards[1].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

        <div class="col">
          <router-link 
            :to="cards[2].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[2].card.image}`)" 
            :alt="`${cards[2].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[3].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[3].card.image}`)" 
            :alt="`${cards[3].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

      </div>

      <router-link to="/colecciones">
        <button class="btn btn-danger">Ver Más Colecciones</button>
      </router-link>
    </div>
</template>

<script>
export default {
  data() {
    return {
      header: "Cócteles por País 🌎",
      cards: [
        { to: {
           name: 'RecipesList', params: { slug: "pais", id: 479, header: 'Mexicanos' } // Mexicano
          },
          card: {
            title: 'cocteles mexicanos',
            image: 'mexico.png'
          } 
        },
        { to: { 
          name: 'RecipesList', params: { slug: "pais", id: 290, header: 'Peruanos' } // Peruano
          },
          card: {
            title: 'cocteles peruanos',
            image: 'peru.png'
          }  
        },
        { to: { 
          name: 'RecipesList', params: { slug: "pais", id: 575, header: 'Chilenos' } // Chileno
          },
          card: {
            title: 'cocteles chilenos',
            image: 'chile.png'
          }   
        },
        { to: { 
          name: 'RecipesList', params: { slug: "pais", id: 297, header: 'Brasileños' } // Brasileño
          },
          card: {
            title: 'cocteles brasilenos',
            image: 'brazil.png'
          }    
        },
      ],
    }
  },
}
</script>

<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
}
.row .col {
  padding-right: 5px;
  padding-left: 5px;
}
.col img {
  width: 100%;
  border-radius: 10px;
}
.w-100 {
  margin: 10px 0;
}
</style>