<template>
  <div class="container">

    <div class="alert alert-dismissible alert-info" v-if="showInstallBanner">
      <div>
        <button type="button" class="close" data-dismiss="alert" @click="goAwayInstallBanner()">&times;</button>
        <img :src="require('@/assets/images/coctelia-logo.png')" />
        <p>¿Quieres agregar Coctelia<br>a su pantalla de inicio?</p>
      </div>
      <button class="btn btn-success" @click.prevent="install">INSTALAR</button>
    </div>

    <div class="alert alert-dismissible alert-info" v-else-if="showShareBanner">
      <div>
        <button type="button" class="close" data-dismiss="alert" @click="goAwayShareBanner()">&times;</button>
        <img :src="require('@/assets/images/coctelia-logo.png')" />
        <p>¡Compartir Coctelia en tus redes!</p>
        <facebook-button
            :shareUrl="shareUrl"
            v-bind:isBlank="false" btnText
        />
        <twitter-button
            :shareUrl="shareUrl"
            v-bind:isBlank="false" btnText
        />
      </div>
    </div>

  </div>
</template>

<script>
let installEvent;
import TwitterButton from "vue-share-buttons/src/components/TwitterButton"
import FacebookButton from "vue-share-buttons/src/components/FacebookButton"
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
export default {
  name: 'Prompts',
  data() {
    return {
      showInstallBanner: false,
      showShareBanner: false,
      shareUrl: "https://coctelia.app/"
    };
  },
  created() {
    if (this.checkInstallCookie() == null) {
      // will only work on Chrome and Android devices
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
        installEvent = e;
        this.showInstallBanner = true;
      });
    }

    // show the share prompt if on iOS devices
    this.showShareBanner = (this.checkSharingCookie() != null) ? false : true;
  },
  methods: {
    goAwayInstallBanner() {
      // hide install banner for 7 days
      this.showInstallBanner = false;
      this.$cookies.set('showInstallBanner', false, 60 * 60 * 24 * 7)
    },
    goAwayShareBanner() {
      // hide share banner for 7 days
      this.showShareBanner = false;
      this.$cookies.set('sharingIsCaring', false, 60 * 60 * 24 * 7);
    },
    checkSharingCookie() {
      return this.$cookies.get('sharingIsCaring');
    },
    checkInstallCookie() {
      return this.$cookies.get('showInstallBanner');
    },
    isIOSDevice() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    },
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    }
  },
  components: {FacebookButton, TwitterButton}
};
</script>

<style scoped>
img {
  width: 75px;
  border-radius: 15px;
  margin-bottom: 10px;
}
p {
  color: #2c3e50;
  font-weight: bold;
}
.btn.btn-success {
  width: 100%;
  font-weight: bold;
  border-bottom: 2px solid #155824;
}
.alert-dismissible {
  padding-right: 20px;
  margin-top: 40px;
}
</style>