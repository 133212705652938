<template>
    <div>
      <h2 v-text="header"></h2>

      <div class="row">

        <div class="col">
          <router-link 
            :to="cards[0].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[0].card.image}`)" 
            :alt="`${cards[0].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[1].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[1].card.image}`)" 
            :alt="`${cards[1].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

        <div class="col">
          <router-link 
            :to="cards[2].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[2].card.image}`)" 
            :alt="`${cards[2].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[3].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[3].card.image}`)" 
            :alt="`${cards[3].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

      </div>

      <router-link to="/colecciones">
        <button class="btn btn-danger">Ver Más Colecciones</button>
      </router-link>
    </div>
</template>

<script>
export default {
  data() {
    return {
      header: "Tipos de Cócteles 🍹",
      cards: [
        { to: {
           name: 'RecipesList', params: { slug: "colecciones", id: 793 } 
          },
          card: {
            title: 'Cremoso',
            image: 'cremoso.jpeg'
          } 
        },
        { to: { 
          name: 'RecipesList', params: { slug: "colecciones", id: 337 } 
          },
          card: {
            title: 'tropical',
            image: 'tropical.jpeg'
          }  
        },
        { to: { 
          name: 'RecipesList', params: { slug: "colecciones", id: 789 } 
          },
          card: {
            title: 'sour',
            image: 'sour.jpeg'
          }   
        },
        { to: { 
          name: 'RecipesList', params: { slug: "colecciones", id: 791 } 
          },
          card: {
            title: 'sin alcohol',
            image: 'sin-alcohol.jpeg'
          }    
        },
      ],
    }
  },
}
</script>

<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
}
.row .col {
  padding-right: 5px;
  padding-left: 5px;
}
.col img {
  width: 100%;
  border-radius: 10px;
}
.w-100 {
  margin: 10px 0;
}
</style>