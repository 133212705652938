<template>
  <ScrollTop>
      <a class="btn btn-light go-top-btn">
        SUBIR
      </a>
  </ScrollTop>
</template>

<script>
import ScrollTop from '@/components/ScrollTop'
export default {
  components: {
    ScrollTop
  }
}
</script>

<style>
.btn.go-top-btn {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 5px 10px;
}
.btn.btn-light.go-top-btn,
.btn.btn-light:hover {
  font-weight: bold;
  color: #fff !important;
  background-color: #28a745;
  border-color: #28a745;
}
</style>