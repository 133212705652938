<template>
    <div class="container wide">
        <div v-for="(recipe, idx) in recipes" :key="idx">
            <div class="row">
                <div class="col header">
                    <h2 v-text="recipe.header"></h2>
                </div>
                <div class="col button">
                    <router-link :to="`/colecciones/${recipe.id}`">
                        <button class="btn btn-danger">Ver Más Cócteles {{ recipe.header }}</button>
                    </router-link>                    
                </div>
            </div>
            <div v-if="loading">
                <Loader />
            </div>
            <div class="row" v-else>
                <div class="col-sm" v-for="(recipe, index) in recipes[idx].recipes" :key="index">
                    <router-link :to="{
                            name: 'SingleRecipe',
                            params: {
                                slug: 'cocteles',
                                id: recipe.id
                            } 
                        }" 
                        tag="img" 
                        :src="recipe.image_url" 
                        :alt="recipe.name"
                        class="border-img" 
                    >
                    </router-link>
                    <p class="recipe-name" v-text="recipe.name"></p>
                </div>
            </div>
        </div>

        <router-link to="/colecciones">
            <button class="btn btn-danger">Ver Más Colecciones</button>
        </router-link>

    </div>
</template>

<script>
import Loader from '@/components/Loader';
export default {
    name: 'HomeCollectionsDesktop',
    data() {
        return {
            loading: true,
            recipes: [
                { header: 'Cubanos', id: 70, recipes: [] },
                { header: 'Tiki', id: 336, recipes: [] },
                { header: 'Fáciles', id: 567, recipes: [] },
                { header: 'Azules', id: 596, recipes: [] },
            ],
        }
    },
    mounted() {
        // get this many recipes
        const perPage = 'per_page=4';
        this.recipes.map((tag) => {
            fetch(`${this.$apiUrl}/posts?tags=${tag.id}&${perPage}`)
                .then(response => response.json())
                .then(data => {
                    data.map((data) => {
                        tag.recipes.push(data.recipe);
                    });
                })
                .finally(() => { this.loading = false });
        });
    },
    components: {Loader}
}
</script>

<style scoped>
img {
    width: 100%;
}
.recipe-name {
    font-weight: bold;
}
.col.button {
    text-align: right;
}
.col.header {
    text-align: left;
}
</style>