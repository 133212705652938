<template>
  <div class="home">
    <h1 v-text="header"></h1>

    <section class="section desktop-collections" v-if="!isMobile()">
      <HomeCollectionsDesktop />
    </section>
    <section class="section collections" v-else>
      <HomeCollections />
    </section>

    <Prompts />

    <section class="section desktop-popular" v-if="!isMobile()">
      <HomePopularRecipesDesktop />
    </section>
    <section class="section popular" v-else>
      <HomePopularRecipes />
    </section>

    <section class="section youtube">
      <router-link to="/videos">
        <figure>
          <img :src="require('@/assets/images/videos-youtube.png')" alt="ver videos de youtube" />
        </figure>
        <button class="btn btn-danger">Ver Videos</button>
      </router-link>
    </section>

    <section class="section desktop-new-recipes" v-if="!isMobile()">
      <HomeNewRecipesDesktop />
    </section>
    <section class="section new-recipes" v-else>
      <HomeNewRecipes />
    </section>

    <section class="section base-spirits">
      <HomeBaseSpirits />
    </section>

    <section class="section cocktail-types">
      <HomeCocktailTypes />
    </section>

    <section class="section cocktail-country">
      <HomeCountries />
    </section>

  </div>
</template>

<script>
import { store } from '@/store.js';
import HomeCollections from '@/components/home/HomeCollections.vue';
import HomeNewRecipes from '@/components/home/HomeNewRecipes.vue';
import HomeBaseSpirits from '@/components/home/HomeBaseSpirits.vue';
import HomeCocktailTypes from '@/components/home/HomeCocktailTypes.vue';
import Prompts from '@/components/home/Prompts';
import HomeCollectionsDesktop from '@/components/home/HomeCollectionsDesktop.vue';
import HomeNewRecipesDesktop from '@/components/home/HomeNewRecipesDesktop.vue';
import HomePopularRecipes from '@/components/home/HomePopularRecipes.vue';
import HomePopularRecipesDesktop from '@/components/home/HomePopularRecipesDesktop.vue';
import HomeCountries from '@/components/home/HomeCountries.vue';
export default {
  name: 'Home',
  data() {
    return {
      header: "Descubre 175+ cócteles",
    }
  },
  mounted() {
    store.isNavOpen = false;
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    HomeNewRecipes, 
    HomeCollections, 
    HomeBaseSpirits,
    Prompts,
    HomeCocktailTypes,
    HomeCollectionsDesktop,
    HomeNewRecipesDesktop,
    HomePopularRecipes,
    HomePopularRecipesDesktop,
    HomeCountries
  }
}
</script>

<style scoped>
.home .collections {
  margin-top: 20px;
}
.youtube img {
  width: 100%;
}
.section {
  margin: 40px 0;
}
.section.youtube .btn {
  margin: 20px 0;
}
</style>