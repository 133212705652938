var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{domProps:{"textContent":_vm._s(_vm.header)}}),_c('div',[_c('form',{staticClass:"form-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control mr-sm-2",attrs:{"type":"search","placeholder":"Buscar","aria-label":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),(_vm.loading)?_c('div',[_c('Loader')],1):_c('div',[_c('ul',{staticClass:"list-group"},_vm._l((_vm.filteredList),function(item,index){return _c('li',{key:index,staticClass:"list-group-item d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"list-group-item font-weight-bold",attrs:{"to":{ 
                                name: _vm.component, 
                                params: { 
                                    slug: _vm.slug,
                                    id: item.id, 
                                    recipe: item,
                                } 
                            }}},[_vm._v(" "+_vm._s(item.name)+" "),(item.video_embed)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v("Video")]):_vm._e()]),_c('span',{staticClass:"badge badge-primary badge-pill",domProps:{"textContent":_vm._s(item.count)}})],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }