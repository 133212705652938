<template>
    <div class="container wide">

        <h2 v-text="header"></h2>

        <RecipesListGrid :recipes="newrecipes" />

        <router-link :to="{ path: '/recetas-nuevas', params: { slug: 'nuevas' } }">
            <button class="btn btn-danger">Ver Más Recetas Nuevas</button>
        </router-link>

    </div>
</template>

<script>
import RecipesListGrid from '@/components/lists/RecipesListGrid';
export default {
    name: 'HomeCollectionsDesktop',
    data() {
        return {
            newrecipes: {
                recipesColOne: [],
                recipesColTwo: [],
            },
            header: "Recetas Nuevas 🍸",
        }
    },
    mounted() {
        // how many recipes I want to fetch
        const perPage = 8;
        fetch(`${this.$apiUrl}/wprm_recipe?per_page=${perPage}`)
        .then(response => response.json())
        .then(data => {
            data.map((data, index) => {
                // evaluate for halfway point and split into columns
                if (index <= (perPage / 2 - 1)) {
                    this.newrecipes.recipesColOne.push(data.recipe);
                } else {
                    this.newrecipes.recipesColTwo.push(data.recipe);
                }
            });
        });
    },
    components: {RecipesListGrid}
}
</script>

