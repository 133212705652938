<template>
    <div class="container">

        <h2 v-text="header"></h2>    

        <RecipesListVertical :recipes="recipes" />

        <router-link :to="{ path: '/recetas-nuevas', params: { slug: 'nuevas' } }">
            <button class="btn btn-danger">Ver Más Recetas Nuevas</button>
        </router-link>

    </div>
</template>

<script>
import RecipesListVertical from '@/components/lists/RecipesListVertical';
export default {
    data() {
        return {
            recipes: [],
            header: "Recetas Nuevas 🍸",
        }
    },
    mounted() {
        fetch(`${this.$apiUrl}/wprm_recipe?per_page=5`)
            .then(response => response.json())
            .then(data => {
                data.map((data) => {
                    const dateObj = new Date(data.date);
                    data.recipe.date = dateObj.getUTCDate() + "/" + (dateObj.getUTCMonth() + 1) + "/" + dateObj.getUTCFullYear();
                    data.recipe.showDate = true;
                    data.recipe.category = data.category;
                    this.recipes.push(data.recipe);
                });
            });
    },
    components: {RecipesListVertical}
}
</script>