<template>
  <div id="app">
    <div id="nav">
      <div class="row">
        <div class="col">
          <div v-if="$route.name != 'Home'">
            <i class="fas fa-chevron-left navigation" @click="goBack"></i>
          </div>
        </div>
        <div class="col-6">
          <router-link 
            to="/" 
            tag="img" 
            :src="require('@/assets/images/coctelia-logo-banner.png')" 
            alt="coctelia logo"
          ></router-link>
        </div>
        <div class="col">
          <Burger></Burger>
        </div>
      </div>
    </div>

    <div class="container-home">
      <router-view :key="$route.path"/>
    </div>
    <ScrollTopArrow></ScrollTopArrow>

    <Sidebar>
      <ul class="sidebar-panel-nav">
        <li>
          <router-link 
            tag="img" 
            :src="require('@/assets/images/coctelia-icono.png')" 
            alt="coctelia" 
            to="/"
            class="home"
          ></router-link>
        </li>
        <li><router-link to="/favoritos"><i class="fas fa-star"></i> Favoritos</router-link></li>
        <li><router-link to="/cocteles"><i class="fas fa-cocktail"></i> Cócteles</router-link></li>
        <li><router-link to="/ingredientes"><i class="fas fa-wine-bottle"></i> Ingredientes</router-link></li>
        <li><router-link to="/colecciones"><i class="fas fa-layer-group"></i> Colecciones</router-link></li>
        <li><router-link to="/videos"><i class="fas fa-video"></i> Videos</router-link></li>
        <li><router-link to="/libros"><i class="fas fa-book"></i> Libros</router-link></li>
        <li><router-link to="/donacion"><i class="fas fa-hand-holding-heart"></i> Donación</router-link></li>
      </ul>
    </Sidebar>

    <Footer />

  </div>
</template>

<script>
import ScrollTopArrow from '@/components/ScrollTopArrow'
import Footer from '@/components/Footer.vue';
import Burger from '@/components/menu/Burger.vue';
import Sidebar from '@/components/menu/Sidebar.vue';
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
        this.$router.go(-1);
    },
  },
  components: {Footer, Burger, Sidebar, ScrollTopArrow}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
}
#app .container-home {
  max-width: 1140px;
  margin: 0 auto;
}
#app h1 {
  margin: 10px auto;
  font-family: 'Abril Fatface', cursive;
}
#app a {
  color: #2c3e50;
}
#app .border-img {
  border-radius: 10px;
}
#nav {
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav img {
  width: 100%;
  max-width: 250px;
}
.fas.fa-chevron-left.navigation {
    position: fixed;
    right: 90%;
    top: 30px;
    font-size: 30px;
    z-index: 1;
}
#app .btn.btn-danger {
  font-weight: bold;
  font-size: 14px;
  border-bottom: 2px solid #751e26;
}
#app .form-inline .form-control {
  width: 100%;
  margin-bottom: 5px;
}
#app figure {
  margin-bottom: 0;
}
#app .container.wide {
    max-width: 1040px;
}
.fas {
  width: 30px;
}
ul.sidebar-panel-nav {
  list-style-type: none;
}
.sidebar-panel-nav li:not(:first-child) {
  text-align: left;
}
ul.sidebar-panel-nav .home {
  width: auto;
  padding-bottom: 20px;
  height: 105px;
}
ul.sidebar-panel-nav > li > a {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
  font-weight: bold;
}
</style>
