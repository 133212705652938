<template>
    <div class="home-collections">

      <Carousel :cards="cards" />

      <router-link to="/colecciones">
          <button class="btn btn-danger">Ver Más Colecciones</button>
      </router-link>

    </div>
</template>

<script>
import Carousel from "@/components/carousel/Carousel";
export default {
  data() {
    return {
      cards: [
        {
          headline: "Cubanos",
          imgName: "cubanos.jpeg",
          to: { name: 'RecipesList', params: { slug: 'colecciones', id: 70 } }
        },
        {
          headline: "Tiki",
          imgName: "tiki.jpeg",
          to: { name: 'RecipesList', params: { slug: 'colecciones', id: 336 } }
        },
        {
          headline: "Azules",
          imgName: "azules.jpeg",
          to: { name: 'RecipesList', params: { slug: 'colecciones', id: 596 } }
        },
        {
          headline: "Fáciles",
          imgName: "faciles.jpeg",
          to: { name: 'RecipesList', params: { slug: 'colecciones', id: 567 } }
        },
      ]
    }
  },
  components: {Carousel}
}
</script>