import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import Vue2TouchEvents from 'vue2-touch-events'
import './registerServiceWorker'
import VueYouTubeEmbed from 'vue-youtube-embed';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDin3ps1aBu4nOZinuW-WYLvOOqPwMh-q4",
  authDomain: "cocktail-recipes-6b1bc.firebaseapp.com",
  projectId: "cocktail-recipes-6b1bc",
  storageBucket: "cocktail-recipes-6b1bc.appspot.com",
  messagingSenderId: "424625763210",
  appId: "1:424625763210:web:95ade26295eccab2a20e73",
  measurementId: "G-5534HL8JDH"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.use(Vue2TouchEvents)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false

// alias
Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$apiUrl = 'https://coctelia.com/wp-json/wp/v2';
Vue.prototype.$apiUrlPopular = 'https://coctelia.com/wp-json/wordpress-popular-posts/v1/popular-posts';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
