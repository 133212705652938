<template>
    <div>
        <div class="row">
            <div class="col" v-for="(recipe, index) in recipes.recipesColOne" :key="index">
                <router-link :to="{
                        name: 'SingleRecipe',
                        params: {
                            slug: 'cocteles',
                            id: recipe.id
                        } 
                    }" 
                    tag="img" 
                    :src="recipe.image_url" 
                    :alt="recipe.name"
                    class="border-img" 
                >
                </router-link>
                <p class="recipe-name" v-text="recipe.name"></p>
            </div>
        </div>
        <div class="row">
            <div class="col" v-for="(recipe, index) in recipes.recipesColTwo" :key="index">
                <router-link :to="{
                        name: 'SingleRecipe',
                        params: {
                            slug: 'cocteles',
                            id: recipe.id
                        } 
                    }" 
                    tag="img" 
                    :src="recipe.image_url" 
                    :alt="recipe.name"
                    class="border-img" 
                >
                </router-link>
                <p class="recipe-name" v-text="recipe.name"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeCollectionsDesktop',
    data() {
        return {}
    },
    props: {
        recipes: {
			type: Object,
			default: () => {}
        }
    }
}
</script>

<style scoped>
img {
    width: 100%;
}
.recipe-name {
    font-weight: bold;
}
</style>