const utensils = [
    {
        id: 775,
        name: 'Coctelera',
        description: "Las cocteleras tienen tres funciones básicas: mezclar o combinar los ingredientes de la receta, enfriar los mismos, y diluir el licor principal."
    },
    {
        id: 776,
        name: 'Colador gusanillo',
        description: "Estos coladores funcionan como un tamiz en atrapando el hielo y pulpa de cítricos dentro de la coctelera para que no se sirva en la copa. El utensilio es hecho de un disco plano al cual está adjunto un muelle en espiral. El colador se posiciona encima de la coctelera y el muelle atrapa los contenidos no deseados."
    },
    {
        id: 888,
        name: 'Colador fino',
        description: "El colador fino tiene la función de colar aún más el trago para asegurar que piezas de hielo o pulpa aún más pequeñas que el colador gusanillo no logró detener. Este colador se sujeta por arriba de la copa mientras se está sirviendo los contenidos de la coctelera."
    },
    {
        id: 784,
        name: 'Colador Julep',
        description: "Hoy en día este colador se usa con el vaso mezclador para mezclar los cócteles que no se agitan. El mismo se posiciona dentro del vaso y al punto de servir el mismo detiene el hielo ser servido en la copa."
    },
    {
        id: 783,
        name: 'Cuchara de bar',
        description: "La cuchara de bar tiene una manija larga para asegurar que llegue hasta el fondo del vaso (o el vaso mezclador) para poder revolver los ingredientes."
    },
    {
        id: 782,
        name: 'Vaso mezclador',
        description: "En caso que todos los ingredientes de una receta son alcohólicos entonces se revuelve en un vaso mezclador usando una cuchara de bar hecha específicamente para este propósito."
    },
    {
        id: 794,
        name: 'Mortero de bar',
        description: "El mortero de bar es un palo que sirve para aplastar diferentes ingredientes para extraer los jugos o su esencia."
    },
]

export default utensils;