<template>
    <div>
      <h2 v-text="header"></h2>

      <div class="row">

        <div class="col">
          <router-link 
            :to="cards[0].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[0].card.image}`)" 
            :alt="`${cards[0].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[1].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[1].card.image}`)" 
            :alt="`${cards[1].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

        <div class="col">
          <router-link 
            :to="cards[2].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[2].card.image}`)" 
            :alt="`${cards[2].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[3].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[3].card.image}`)" 
            :alt="`${cards[3].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

        <div class="col">
          <router-link 
            :to="cards[4].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[4].card.image}`)" 
            :alt="`${cards[4].card.title}`"
          >
          </router-link>
        </div>

        <div class="col">
          <router-link 
            :to="cards[5].to" 
            tag="img" 
            :src="require(`@/assets/images/${cards[5].card.image}`)" 
            :alt="`${cards[5].card.title}`"
          >
          </router-link>
        </div>

        <div class="w-100"></div>

      </div>

      <router-link to="/ingredientes">
        <button class="btn btn-danger">Ver Más Ingredientes</button>
      </router-link>
    </div>
</template>

<script>
export default {
  data() {
    return {
      header: "Licores 🍾",
      cards: [
        { to: { name: 'RecipesList', params: { slug: "licor", id: 3, header: 'Ron' } },
          card: { title: 'Ron', image: 'ron.jpeg' } 
        },
        { to: { name: 'RecipesList', params: { slug: "licor", id: 45, header: 'Tequila' } },
          card: { title: 'Tequila', image: 'tequila.jpeg' }
        },
        { to: { name: 'RecipesList', params: { slug: "licor", id: 94, header: 'Ginebra' } },
          card: { title: 'Ginebra', image: 'ginebra.jpeg' }
        },
        { to: { name: 'RecipesList', params: { slug: "licor", id: 53, header: 'Whisky' } },
          card: { title: 'Whisky', image: 'whisky.jpeg' }
        },
        { to: { name: 'RecipesList', params: { slug: "licor", id: 72, header: 'Brandy' } },
          card: { title: 'Brandy', image: 'brandy.jpeg' }
        },
        { to: { name: 'RecipesList', params: { slug: "licor", id: 65, header: 'Vodka' } },
          card: { title: 'Vodka', image: 'vodka.jpeg' }
        },
      ],
    }
  },
}
</script>

<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
}
.row .col {
  padding-right: 5px;
  padding-left: 5px;
}
.col img {
  width: 100%;
  border-radius: 10px;
}
.w-100 {
  margin: 10px 0;
}
</style>