<template>
	<div class="container">
        <h1 v-text="header"></h1>
        <div>
            <form class="form-inline">
                <input 
                    class="form-control mr-sm-2" 
                    type="search" 
                    placeholder="Buscar" 
                    aria-label="Search" 
                    v-model="search"
                >
            </form>
            <div v-if="loading">
                <Loader />
            </div>
            <div v-else>
                <ul class="list-group">
                    <li 
                        v-for="(item, index) in filteredList" 
                        :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        <router-link 
                            :to="{ 
                                name: component, 
                                params: { 
                                    slug: slug,
                                    id: item.id, 
                                    recipe: item,
                                } 
                            }"
                            class="list-group-item font-weight-bold"
                        >
                        {{ item.name }} <span class="badge badge-pill badge-danger" v-if="item.video_embed">Video</span>
                        </router-link>
                        <span class="badge badge-primary badge-pill" v-text="item.count"></span>
                    </li>
                </ul>
            </div>
        </div>
	</div>
</template>

<script>
import { store } from '@/store.js';
import Loader from '@/components/Loader';
export default {
    name: 'Lists',
    data() {
        return {
            header: '',
            list: [],
            slug: '',
            search: '',
            component: '',
            loading: false,
            endpoint: '',
        }
    },
    async mounted() {
        window.scrollTo(0,0);
        store.isNavOpen = false;

        // determine route path
        // establish RecipesList variables for routing
        // determine which endpoint to fetch data from
        switch(this.$route.name) {
            case 'Ingredientes':
                this.header = 'Ingredientes';
                this.slug = 'ingredientes';
                this.component = 'RecipesList';
                this.endpoint = 'wprm_ingredient?';
                break;
            case 'Colecciones':
                this.header = 'Colecciones';
                this.slug = 'colecciones';
                this.component = 'RecipesList';
                this.endpoint = 'tags?';
                break;
            case 'Cocteles':
                this.header = 'Cócteles';
                this.slug = 'cocteles';
                this.component = 'SingleRecipe';
                this.endpoint = 'wprm_recipe?';
                break;
            case 'Videos':
                this.header = 'Videos';
                this.slug = 'videos';
                this.component = 'SingleRecipe';
                this.endpoint = 'posts?tags=855&';
                break;
        }

        this.getList(`${this.$apiUrl}/${this.endpoint}per_page=100&page=1`);
    },
    methods: {
        async getList(url) {

            this.loading = true;
            fetch(url)
                .then(response => response.json())
                .then(data => {

                    // check if data returned == 100
                    if (data.length == 100) {
                        // if so then fetch more data
                        this.getList(`${this.$apiUrl}/${this.endpoint}per_page=100&page=2`);
                    }

                    data.filter(data => {
                        if (this.slug == 'colecciones') {
                            // filter out certain tags
                            // 661 con licor de naranja
                            // 565 maracuya
                            return (data.id == 661 || data.id == 565) ? false : true;
                        } else {
                            return data;
                        }
                    }).map((data) => {
                        // uppercase the ingredient names
                        data.name = data.name ? data.name : data.recipe.name
                        const words = data.name.split(" ");
                        data.name = words.map((word) => { 
                            return word[0].toUpperCase() + word.substring(1); 
                        }).join(" ");

                        if (data.recipe) {
                            this.list.push(data.recipe);
                        } else {
                            this.list.push(data);
                        }
                    });

                    // alphabetize the list
                    this.list = this.sortedList(this.list);
                })
                .finally(() => (this.loading = false));
        },
        sortedList(list) {
            function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
            return list.sort(compare);
        }
    },
    computed: {
        filteredList: function() {
            var self = this;
            return this.list.filter(function(item) {
                return item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
            });
        },
    },
    components: {Loader}
}
</script>

<style scoped>
.badge-primary {
    font-size: 1rem;
    background-color: #296f9c;
}
.list-group a {
    padding: 0;
    border: none;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
}
.form-inline {
    display: block;
    margin: 20px 0;
}
</style>