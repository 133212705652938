<template>
    <div class="container wide">

        <h2 v-text="header"></h2>

        <RecipesListGrid :recipes="popular" />

        <router-link :to="{ path: '/recetas-populares', params: { slug: 'populares' } }">
            <button class="btn btn-danger">Ver Más Recetas Populares</button>
        </router-link>

    </div>
</template>

<script>
import RecipesListGrid from '@/components/lists/RecipesListGrid';
export default {
    name: 'HomeCollectionsDesktop',
    data() {
        return {
            popular: {
                recipesColOne: [],
                recipesColTwo: [],
            },
            header: "Popular 📈",
        }
    },
    mounted() {
        // how many recipes I want to fetch
        const limit = 6;
        fetch(`https://coctelia.com/wp-json/wordpress-popular-posts/v1/popular-posts?term_id=-256,-244&limit=${limit}`)
        .then(response => response.json())
        .then(data => {
            // filter out any post that doesn't have a recipe
            data.map((data, index) => {
                // evaluate halfway point and split into columns
                if (index <= (limit / 2 - 1)) {
                    this.popular.recipesColOne.push(data.recipe);
                } else {
                    this.popular.recipesColTwo.push(data.recipe);
                } 
            });
        });
    },
    components: {RecipesListGrid}
}
</script>

<style scoped>
img {
    width: 100%;
}
.recipe-name {
    font-weight: bold;
}
</style>